import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '../../buttons/Button';
import SocialLoginButton from '../../buttons/SocialLogin';
import Close from '../../icons/Close';
import SubscriptionForm from '../../subscription/SubscriptionForm';

import styles from '../../../../styles/micromag/modals/subscribe-modal-noel.module.scss';

import curvedLine from '../../../../img/micromag/noel-curved-line.png';
import hand from '../../../../img/micromag/noel-hand-2.png';

const propTypes = {
    nextUrl: PropTypes.string,
    className: PropTypes.string,
    onDismiss: PropTypes.func,
    onComplete: PropTypes.func,
    withDismiss: PropTypes.bool,
};

const defaultProps = {
    nextUrl: null,
    className: null,
    onDismiss: null,
    onComplete: null,
    withDismiss: false,
};

function Noel({ nextUrl, className, onDismiss, onComplete, withDismiss }) {
    const intl = useIntl();
    const url = useUrlGenerator();

    const onSubscribed = useCallback(() => {
        if (onComplete !== null) {
            onComplete();
        }
    }, [onComplete]);

    const onClickDismiss = useCallback(() => {
        if (onDismiss !== null) {
            onDismiss();
        }
    }, [onDismiss]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.withDismiss]: withDismiss,
                },
            ])}
        >
            <div className={styles.modal}>
                <div className={styles.content}>
                    <div className={styles.top}>
                        <p>
                            <span className={styles.outerBox}>
                                <span className={styles.innerBox}>
                                    <FormattedMessage id="micromag.subscribe_modal_noel.top_1" />
                                </span>
                            </span>
                            <span className={styles.outerBox}>
                                <span className={styles.innerBox}>
                                    <FormattedMessage id="micromag.subscribe_modal_noel.top_2" />
                                </span>
                            </span>
                        </p>
                    </div>
                    <div className={styles.inner}>
                        {withDismiss ? (
                            <Button
                                className={styles.closeButton}
                                onClick={onClickDismiss}
                                title={intl.formatMessage({
                                    id: 'micromag.subscribe_modal_dismiss',
                                })}
                            >
                                <Close />
                            </Button>
                        ) : null}
                        <h1 className={styles.title}>
                            <strong>Écoutez un épisode exclusif de la 6e saison</strong> du balado
                            les <i>Pires moments de l’Histoire</i>.
                        </h1>
                        <div className={styles.center}>
                            <p className={styles.find}>
                                <img className={styles.line} src={curvedLine} alt="line" />
                                <span>
                                    <FormattedMessage id="micromag.subscribe_modal_noel.find" />
                                </span>
                            </p>
                            <div className={styles.visual}>
                                <img className={styles.hand} src={hand} alt="hand" />
                            </div>
                        </div>
                        <div className={styles.actions}>
                            <p className={styles.cta}>
                                <FormattedMessage id="micromag.subscribe_modal_noel.cta" />
                            </p>
                            <SubscriptionForm
                                action={url('subscriptions.micromag')}
                                subscription="micromag"
                                source="micromag"
                                formClassName={styles.form}
                                fieldClassName={styles.field}
                                inputClassName={styles.input}
                                buttonClassName={styles.submitButton}
                                placeholderText="votre@courriel.ca"
                                onComplete={onSubscribed}
                                withThickBorders
                            />
                            <div className={styles.social}>
                                <SocialLoginButton
                                    provider="facebook"
                                    nextUrl={nextUrl}
                                    source="micromag"
                                    className={styles.button}
                                    withThickBorders
                                    withArrow
                                />
                                <SocialLoginButton
                                    provider="google"
                                    nextUrl={nextUrl}
                                    source="micromag"
                                    className={styles.button}
                                    withThickBorders
                                    withArrow
                                />
                            </div>
                        </div>

                        {withDismiss ? (
                            <Button className={styles.dismissButton} onClick={onClickDismiss}>
                                <FormattedMessage id="micromag.subscribe_modal_dismiss" />
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

Noel.propTypes = propTypes;
Noel.defaultProps = defaultProps;

export default Noel;
