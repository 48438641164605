/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Basic from './Basic';

const propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

const defaultProps = {
    title: null,
    subtitle: null,
};

function France({ title, subtitle, ...props }) {
    const intl = useIntl();

    return (
        <Basic
            {...props}
            theme="france"
            source="micromag-france"
            subscription="micromag-france"
            title={
                title || (
                    <FormattedMessage
                        defaultMessage="Vous en voulez encore ?"
                        description="Modal title"
                    />
                )
            }
            subtitle={
                subtitle || (
                    <FormattedMessage
                        defaultMessage="Lavez-vous soigneusement les mains et inscrivez-vous pour ne pas manquer notre prochain micromag qui sortira dans 3 mois, 3h et 33 minutes, précisément."
                        description="Modal description"
                    />
                )
            }
            formTitle={
                <FormattedMessage
                    defaultMessage="Inscrivez votre adresse email"
                    description="Modal description"
                />
            }
            formWarning={
                <FormattedMessage
                    defaultMessage="En poursuivant, vous acceptez de recevoir notre Micromag gratuitement par courriel."
                    description="Form label"
                />
            }
            placeholderMessage={intl.formatMessage({
                defaultMessage: 'Adresse email',
                description: 'Modal placeholder',
            })}
        />
    );
}

France.propTypes = propTypes;
France.defaultProps = defaultProps;

export default France;
