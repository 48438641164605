/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import { getComponentFromName } from '../../lib/utils';

import * as Components from './modals/index';

const propTypes = {
    component: PropTypes.string,
};

const defaultProps = {
    component: null,
};

function SubscribeModal({ component, ...props }) {
    const SubscribeModalComponent = getComponentFromName(Components, component, 'Basic');
    return <SubscribeModalComponent {...props} />;
}

SubscribeModal.propTypes = propTypes;
SubscribeModal.defaultProps = defaultProps;

export default SubscribeModal;
