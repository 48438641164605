import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMicromagFirstVideo from '../../hooks/useMicromagFirstVideo';
import * as AppPropTypes from '../../lib/PropTypes';

import Button from '../buttons/Button';
import Arrow from '../icons/Arrow';
import MicromagCover from './MicromagCover';

import styles from '../../../styles/micromag/micromag-first-screen-button.module.scss';

const propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    micromag: AppPropTypes.micromag,
    isStatic: PropTypes.bool,
    coverClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    href: null,
    onClick: null,
    micromag: null,
    isStatic: false,
    coverClassName: null,
    className: null,
};

function MicromagFirstScreenButton({
    href,
    onClick,
    micromag,
    isStatic,
    coverClassName,
    className,
}) {
    const video = useMicromagFirstVideo(micromag);
    return (
        <Button
            href={href}
            onClick={onClick}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {isStatic ? (
                <div
                    className={classNames([
                        styles.cover,
                        styles.videoContainer,
                        { [coverClassName]: coverClassName !== null },
                    ])}
                >
                    <video
                        className={styles.video}
                        src={video}
                        type="video/mp4"
                        autoPlay
                        muted
                        playsInline
                    />
                </div>
            ) : (
                <MicromagCover
                    micromag={micromag}
                    className={classNames([
                        styles.cover,
                        { [coverClassName]: coverClassName !== null },
                    ])}
                />
            )}
            <div className={styles.label}>
                <Arrow
                    direction="left"
                    backgroundColor="rgba(0 0 0 / 0)"
                    className={styles.arrow}
                />
                <FormattedMessage defaultMessage="Voir du début" description="Button label" />
            </div>
        </Button>
    );
}

MicromagFirstScreenButton.propTypes = propTypes;
MicromagFirstScreenButton.defaultProps = defaultProps;

export default MicromagFirstScreenButton;
