import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/micromag/micromag-cover.module.scss';

import freeSticker from '../../../img/micromag/free.png';
import cover from '../../../img/micromag/micromag-cover-00.png';

import Loadable from 'react-loadable';
const Viewer = Loadable({
    loader: () => import('@micromag/viewer').then(({ default: Component }) => Component),
    loading: () => null,
});
Viewer.displayName = 'MicromagViewer';

const propTypes = {
    micromag: AppPropTypes.micromag,
    withFreeStick: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    micromag: null,
    withFreeStick: false,
    className: null,
};

function MicromagCover({ micromag, withFreeStick, className }) {
    const coverMicromag = useMemo(
        () =>
            micromag !== null
                ? {
                      ...micromag,
                      components: micromag.components.slice(0, 1),
                  }
                : null,
        [micromag],
    );

    return (
        <div
            className={classNames([
                styles.container,
                { [styles.withViewer]: coverMicromag !== null, [className]: className !== null },
            ])}
        >
            <div className={styles.frame}>
                {withFreeStick ? (
                    <img src={freeSticker} className={styles.sticker} alt="Gratuit" />
                ) : null}
                {coverMicromag !== null ? (
                    <Viewer
                        story={coverMicromag}
                        withoutMenu
                        withoutGestures
                        memoryRouter
                        neighborScreensMounted={null}
                        className={styles.cover}
                        withoutPlaybackControls
                    />
                ) : (
                    <img src={cover} className={styles.cover} alt="" />
                )}
            </div>
        </div>
    );
}

MicromagCover.propTypes = propTypes;
MicromagCover.defaultProps = defaultProps;

export default MicromagCover;
