// import { useSetUser } from '@folklore/auth';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSite } from '../../../contexts/SiteContext';
import Button from '../../buttons/Button';
import SocialLoginButton from '../../buttons/SocialLogin';
import Contest from '../../forms/Contest';
import Close from '../../icons/Close';
import SubscriptionForm from '../../subscription/SubscriptionForm';

import styles from '../../../../styles/micromag/modals/subscribe-modal.module.scss';

import freeSticker from '../../../../img/micromag/free.png';
import dessine from '../../../../img/micromag/gagner-dessine-bande.jpg';
import fouki from '../../../../img/micromag/gagner-fouki.png';
import guignolee from '../../../../img/micromag/gagner-guignolee-medias.png';
import livre20ans from '../../../../img/micromag/gagner-livre20ans.png';
// eslint-disable-next-line camelcase
import bd_piresmoments from '../../../../img/micromag/gagner-moments.png';
import defaultSticker from '../../../../img/micromag/micromag-subscribe-sticker.png';

const stickers = {
    fouki,
    bd_piresmoments,
    livre20ans,
    dessine,
    guignolee,
};

const propTypes = {
    nextUrl: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    theme: PropTypes.string,
    sticker: PropTypes.string,
    stickerCustom: PropTypes.string,
    completeDelay: PropTypes.number,
    className: PropTypes.string,
    onDismiss: PropTypes.func,
    onComplete: PropTypes.func,
    withDismiss: PropTypes.bool,
    withContest: PropTypes.string,
    source: PropTypes.string,
    subscription: PropTypes.string,
    placeholderMessage: PropTypes.string,
    formTitle: PropTypes.node,
    formWarning: PropTypes.node,
};

const defaultProps = {
    nextUrl: null,
    title: null,
    subtitle: null,
    theme: null,
    sticker: null,
    stickerCustom: null,
    completeDelay: 1000,
    className: null,
    onDismiss: null,
    onComplete: null,
    withDismiss: false,
    withContest: 'micromag',
    source: 'micromag',
    subscription: 'micromag',
    placeholderMessage: null,
    formTitle: null,
    formWarning: null,
};

function Basic({
    nextUrl,
    title,
    subtitle,
    theme,
    sticker: initialSticker,
    stickerCustom,
    completeDelay,
    className,
    onDismiss,
    onComplete,
    withDismiss,
    withContest,
    source,
    subscription,
    placeholderMessage,
    formTitle,
    formWarning,
}) {
    const sticker = initialSticker || null;
    const intl = useIntl();
    const url = useUrlGenerator();
    const { contests = [] } = useSite();
    const contest = useMemo(
        () =>
            contests.find(
                ({ entrypoints = [], isStarted = false }) =>
                    entrypoints.indexOf('micromag_modal') !== -1 && isStarted,
            ) || null,
        [],
    );
    const [contestVisible, setContestVisible] = useState(contest !== null);
    const [subscribed, setSubscribed] = useState(false);
    const [wasSubscribed, setWasSubscribed] = useState(false);
    const onFormComplete = useCallback(
        ({ wasSubscribed: newWasSubscribed = false, contests: newContests = [] }) => {
            if (contest !== null) {
                setContestVisible(newContests.findIndex(({ id }) => id === contest.id) === -1);
            }
            setSubscribed(true);
            setWasSubscribed(newWasSubscribed);
        },
        [withContest, setContestVisible],
    );
    const onClickDismiss = useCallback(() => {
        if (onDismiss !== null) {
            onDismiss();
        }
    }, [onDismiss]);

    const defaultStickerImage =
        sticker !== null && typeof stickers[sticker] !== 'undefined' ? stickers[sticker] : null;
    const { url: customStickerImage = null } = stickerCustom !== null ? stickerCustom || {} : {};
    const stickerImage = customStickerImage || defaultStickerImage || null;

    const onCloseMicromagContest = useCallback(() => {
        onComplete();
    }, [onComplete]);

    useEffect(() => {
        let timeout = null;
        if (subscribed && !contestVisible) {
            timeout = setTimeout(() => {
                if (onComplete !== null) {
                    onComplete();
                }
            }, completeDelay);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [contestVisible, subscribed, completeDelay, onComplete]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles[theme]]: theme !== null,
                    [styles.withDismiss]: withDismiss,
                },
            ])}
        >
            <div className={styles.modal}>
                <div className={styles.content}>
                    {!subscribed && !withDismiss ? (
                        <img
                            className={classNames([
                                styles.sticker,
                                {
                                    [styles[sticker]]: sticker !== null,
                                    [styles.customSticker]: customStickerImage !== null,
                                },
                            ])}
                            src={stickerImage || defaultSticker}
                            alt="URBANIA sticker"
                        />
                    ) : null}

                    {!subscribed ? (
                        <img className={styles.freeSticker} src={freeSticker} alt="Free Sticker" />
                    ) : null}

                    {withDismiss ? (
                        <Button
                            className={styles.closeButton}
                            onClick={onClickDismiss}
                            title={intl.formatMessage({ id: 'micromag.subscribe_modal_dismiss' })}
                        >
                            <Close />
                        </Button>
                    ) : null}

                    {subscribed ? (
                        <div>
                            <div className={styles.thanks}>
                                {wasSubscribed ? (
                                    <FormattedMessage
                                        defaultMessage="Re-bienvenue!"
                                        description="Modal thanks"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Vous êtes maintenant abonné·e au Micromag!"
                                        description="Modal thanks"
                                    />
                                )}
                            </div>
                            {contest && contestVisible ? (
                                <Contest
                                    contest={contest.id}
                                    withCancel
                                    onComplete={onCloseMicromagContest}
                                    onCancel={onCloseMicromagContest}
                                />
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <h1 className={styles.title}>
                                {title || (
                                    <FormattedMessage
                                        defaultMessage="Pour poursuivre le Micromag"
                                        description="Modal title"
                                    />
                                )}
                            </h1>

                            <div className={styles.actions}>
                                <p className={styles.formSpacer}>
                                    {formTitle || (
                                        <FormattedMessage
                                            defaultMessage="Inscrivez votre # de tel. ou courriel"
                                            description="Form label"
                                        />
                                    )}
                                </p>
                                <SubscriptionForm
                                    action={url('subscriptions.micromag')}
                                    subscription={subscription}
                                    source={source}
                                    formClassName={styles.form}
                                    fieldClassName={styles.field}
                                    inputClassName={styles.input}
                                    buttonClassName={styles.submitButton}
                                    onComplete={onFormComplete}
                                    placeholderMessage={
                                        placeholderMessage ||
                                        intl.formatMessage({
                                            defaultMessage: 'Votre # de tél. ou courriel',
                                            description: 'Modal placeholder',
                                        })
                                    }
                                />
                                <p className={styles.formSpacer}>
                                    <FormattedMessage
                                        defaultMessage="ou"
                                        description="Form label"
                                    />
                                </p>
                                <div className={styles.social}>
                                    <SocialLoginButton
                                        provider="facebook"
                                        nextUrl={nextUrl}
                                        source="micromag"
                                        className={styles.button}
                                        withArrow
                                    />
                                    <SocialLoginButton
                                        provider="google"
                                        nextUrl={nextUrl}
                                        source="micromag"
                                        className={styles.button}
                                        withArrow
                                    />
                                </div>
                                {subtitle !== null ? (
                                    <p className={styles.subtitle}>{subtitle}</p>
                                ) : null}
                                <p className={styles.formMessage}>
                                    {formWarning || (
                                        <FormattedMessage
                                            defaultMessage="En poursuivant, vous acceptez de recevoir notre Micromag gratuitement par courriel ou SMS."
                                            description="Form label"
                                        />
                                    )}
                                </p>
                            </div>
                        </div>
                    )}
                    {withDismiss && !subscribed ? (
                        <Button className={styles.dismissButton} onClick={onClickDismiss}>
                            <FormattedMessage id="micromag.subscribe_modal_dismiss" />
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

Basic.propTypes = propTypes;
Basic.defaultProps = defaultProps;

export default Basic;
