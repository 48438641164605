import { useRoutes, useUrlGenerator } from '@folklore/routes';
import React, { useEffect, useRef } from 'react';
import { Switch, useLocation } from 'wouter';

import { useStatusCode, useResetNavigation } from '../contexts/NavigationContext';
import PageRoute from './PageRoute';
import MicromagLayout from './layouts/Micromag';
import ErrorPage from './pages/Error';
import Home from './pages/Home';
import Micromag from './pages/Micromag';
import Page from './pages/Page';

import '../../styles/main.scss';

const propTypes = {};

const defaultProps = {};

function App() {
    const statusCode = useStatusCode();
    const resetNavigation = useResetNavigation();
    const routes = useRoutes();
    const generateUrl = useUrlGenerator();
    const [pathname] = useLocation();
    const pageUrl = pathname !== '/' ? pathname.replace(/\/$/, '') : pathname;
    const isInitialPageview = useRef(true);
    const firstUrl = useRef(pageUrl);

    if (isInitialPageview.current && firstUrl.current !== pageUrl) {
        isInitialPageview.current = false;
    }

    // Reset request on history change
    useEffect(() => () => resetNavigation(), [pathname]);

    return (
        <Switch>
            <PageRoute
                exact
                path={routes.home}
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                renderPage={(page) => <Home page={page} />}
            />
            <PageRoute
                exact
                path="/abonnement"
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                renderPage={(page) => <Home page={page} />}
            />
            <PageRoute
                exact
                path={routes['micromags.stats']}
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                getPageUrl={({ params: { micromag } }) =>
                    generateUrl('micromags.stats', {
                        micromag,
                    })
                }
                renderPage={(page, { params: { page: pageSlug } }) => (
                    <Page page={page} pageSlug={pageSlug} />
                )}
            />
            <PageRoute
                exact
                path={routes['micromags.show.wildcard']}
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                getPageUrl={({ params: { micromag } }) =>
                    generateUrl('micromags.show', {
                        micromag,
                    })
                }
                renderPage={(page, { params: { micromag: micromagSlug } }) => (
                    <Micromag micromag={page} micromagSlug={micromagSlug} />
                )}
            />
            <PageRoute
                exact
                path={routes['micromags.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                getPageUrl={({ params: { micromag } }) =>
                    generateUrl('micromags.show', {
                        micromag,
                    })
                }
                renderPage={(page, { params: { micromag: micromagSlug } }) => (
                    <Micromag micromag={page} micromagSlug={micromagSlug} />
                )}
            />
            <PageRoute
                exact
                path={routes['pages.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                renderPage={(page, { params: { page: pageSlug } }) => (
                    <Page page={page} pageSlug={pageSlug} />
                )}
            />
            <PageRoute
                path="*"
                withoutErrorPage
                isInitialPageview={isInitialPageview.current}
                layoutComponent={MicromagLayout}
                renderPage={() => <ErrorPage statusCode={statusCode} />}
            />
        </Switch>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
